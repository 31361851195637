import {removeAllFromClass} from '../helpers/dom-helpers'
const triggerClass = 'js-tab-trigger';
const contentClass = 'js-tab-content';
const activeClass = 'is-active';

let elements = document.getElementsByClassName(triggerClass);
Array.from(elements).forEach(function (element) {
    element.addEventListener('click', togglerInit);
});

function togglerInit() {
    let targetID = this.dataset.target;
    let target = document.getElementById(targetID)
    removeAllFromClass(triggerClass, activeClass)
    removeAllFromClass(contentClass, activeClass)

    this.classList.add(activeClass)
    if (target) {
        target.classList.add(activeClass)
    }
}
